import { DocumentList } from '@fingo/lib/components/lists';
import { INVOICE_TRANSFER_INFO } from '@fingo/lib/graphql';
import dayjs from '@fingo/lib/config/dayjs';
import React, { useState } from 'react';
import { FACTORING_COLUMNS } from '../../constants/treasury';
import RaiseOperativeRequestIconButton from '../../../support/components/buttons/RaiseOperativeRequestIconButton';

const TodaysConciliations = () => {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);

  return (
    <DocumentList
      trackerId="Transfers"
      type="transfer-panel"
      headerTitle="Facturas conciliadas hoy"
      queryDocument={INVOICE_TRANSFER_INFO}
      includeHeaders={[
        'folio',
        'company_MasterEntity_Name',
        'receiver_Name',
        'anticipateAmount',
        'transferAmount',
      ]}
      mobileHeaders={[
        'folio',
        'company_MasterEntity_Name',
        'receiver_Name',
        'anticipateAmount',
      ]}
      countryFilter
      showExportInvoice
      preColumns={FACTORING_COLUMNS}
      onSelectionModelChange={(ids) => setSelectedDocumentIds(ids)}
      defaultFilterProps={{
        showStatesFilter: false,
        showDatesFilter: false,
        showRefresh: true,
        showCompanyIssuerFilter: false,
      }}
      flexEndButtons={() => (
        <RaiseOperativeRequestIconButton documentIds={selectedDocumentIds} />
      )}
      showFilters
      selectionModel={selectedDocumentIds}
      checkboxSelection
      customVariables={{
        allIssuedCompany: false,
        receiverId: null,
        companyId: null,
        receiver_Rut: null,
        dateIssued_Gte: null,
        dateIssued_Lte: null,
        status: 'Finished',
        collectionDate: dayjs().format('YYYY-MM-DD'),
      }}
      density="compact"
      initialOrderBy="company_MasterEntity_Name"
    />
  );
};

export default TodaysConciliations;
