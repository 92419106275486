import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import MenuButton from '@fingo/lib/components/buttons/MenuButton';
import { MoneyInput } from '@fingo/lib/components/inputs';
import React, { useState, useEffect } from 'react';
import { MoneyType } from '@fingo/lib/propTypes';
import PropTypes from 'prop-types';
import useCreateNonOperativeMovement from '../../../../../hooks/useCreateNonOperativeMovement';

const NonOperativeMovement = ({
  debit, credit, setDebitMovementsId, setCreditMovementsId, disabled,
}) => {
  const [selectedNonOperativeMovementType, setSelectedNonOperativeMovementType] = useState('');
  const [conciliationDescription, setConciliationDescription] = useState('');
  const [initialAmountToCondoneValue, setInitialAmountToCondoneValue] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const currency = debit?.currency?.code || credit?.currency?.code || null;

  const calculateAmountToCondone = (debitAmount, creditAmount) => {
    const difference = debitAmount - creditAmount;
    return difference;
  };

  const [amountToCondoneValue, setAmountToCondoneValue] = useState();

  const [debitValue, setDebitValue] = useState();
  const [creditValue, setCreditValue] = useState();

  useEffect(() => {
    const difference = calculateAmountToCondone(debit?.amount || 0, credit?.amount || 0);
    setAmountToCondoneValue(difference);
    setInitialAmountToCondoneValue(Math.abs(difference));

    if (debit.amount > credit.amount) {
      setDebitValue(debit.amount);
      setCreditValue(difference);
    } else {
      setCreditValue(credit.amount);
      setDebitValue(Math.abs(difference));
    }
  }, [debit, credit]);

  const updateValues = (newAmountToCondoneValue) => {
    if (newAmountToCondoneValue > amountToCondoneValue) {
      setErrorMessage('El monto no puede ser mayor que el monto a condonar.');
    }
    setErrorMessage('');
    if (debit !== 0 && credit === 0) {
      setCreditValue(Math.abs(newAmountToCondoneValue));
    } else if (debit === 0 && credit !== 0) {
      setDebitValue(Math.abs(newAmountToCondoneValue));
    } else if (debit.amount < credit.amount) {
      setDebitValue(Math.abs(newAmountToCondoneValue));
    } else if (credit.amount < debit.amount) {
      setCreditValue(Math.abs(newAmountToCondoneValue));
    }
  };

  useEffect(() => {
    updateValues(amountToCondoneValue);
  }, [amountToCondoneValue]);

  const handleAmountChange = (value) => {
    if (value > amountToCondoneValue) {
      setErrorMessage('El monto no puede ser mayor que el monto a condonar.');
    } else {
      setErrorMessage('');
      setAmountToCondoneValue(value);
    }
  };

  const resetValues = () => {
    setSelectedNonOperativeMovementType('');
    setConciliationDescription('');
    setAmountToCondoneValue(0);
    setErrorMessage('');
  };

  const onCallback = (data) => {
    const conciliationMovementId = data.createNonOperativeMovement.relatedConciliationMovement.id;
    if (debitValue > creditValue) {
      setCreditMovementsId((prev) => [...prev, conciliationMovementId]);
    } else if (creditValue > debitValue) {
      setDebitMovementsId((prev) => [...prev, conciliationMovementId]);
    } else if (debitValue === creditValue) {
      if (debit !== 0 && credit === 0) {
        setCreditMovementsId((prev) => [...prev, conciliationMovementId]);
      } else {
        setDebitMovementsId((prev) => [...prev, conciliationMovementId]);
      }
    }
    resetValues();
  };

  const adjustedDebitValue = () => {
    if (debitValue === creditValue) {
      return (debit !== 0 && credit === 0) ? 0 : debitValue;
    }
    return (debitValue > creditValue) ? 0 : debitValue;
  };

  const adjustedCreditValue = () => {
    if (debitValue === creditValue) {
      return (debit === 0 && credit !== 0) ? 0 : creditValue;
    }
    return (creditValue > debitValue) ? 0 : creditValue;
  };

  const [
    [createMovement],
    nonOperativeMovementsType,
  ] = useCreateNonOperativeMovement({
    description: conciliationDescription,
    movementTypeId: selectedNonOperativeMovementType,
    active: adjustedDebitValue(),
    passive: adjustedCreditValue(),
    result: 0,
    evidence: null,
    currency,
  }, onCallback);

  const nonOperativeMovementsOptions = nonOperativeMovementsType?.filter((movement) => movement
    .isActive === true) || [];

  const handleCreateMovement = () => {
    if (amountToCondoneValue > initialAmountToCondoneValue) {
      setErrorMessage(`El monto no puede ser mayor a ${initialAmountToCondoneValue}`);
    } else {
      setErrorMessage('');
      updateValues(amountToCondoneValue);
      createMovement();
    }
  };

  return (
    <Accordion
      expandIconPosition="start"
      bgcolor="gray.A100"
      elevation="0.2"
      sx={{ backgroundColor: 'gray.A100', mr: 2, pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? 0.5 : 1, padding: 1 }}
    >
      <AccordionSummary
        expandIconPosition="start"
        bgcolor="gray.A100"
        expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <Typography>Crear movimiento no operativo</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography variant="subtitle1">Tipo</Typography>
            <MenuButton
              minWidth="100%"
              options={nonOperativeMovementsOptions}
              setSelectedOption={setSelectedNonOperativeMovementType}
              selectedOption={selectedNonOperativeMovementType}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Monto</Typography>
            <MoneyInput
              disabled={amountToCondoneValue === 0}
              value={Math.abs(amountToCondoneValue)}
              currency={currency}
              name="Amount"
              setValue={handleAmountChange}
              error={!!errorMessage}
              fullWidth
            />
            {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Descripción</Typography>
            <TextField
              name="description"
              value={conciliationDescription}
              onChange={({ target: { value } }) => setConciliationDescription(value)}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 'auto' }}
              size="small"
              disabled={amountToCondoneValue === 0}
              onClick={() => {
                handleCreateMovement();
              }}
            >
              Crear movimiento no operativo
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

NonOperativeMovement.propTypes = {
  debit: MoneyType.isRequired,
  credit: MoneyType.isRequired,
  setDebitMovementsId: PropTypes.func.isRequired,
  setCreditMovementsId: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

NonOperativeMovement.defaultProps = {
  disabled: false,
};

export default NonOperativeMovement;
